'use client'
import { useState } from 'react'
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import avatar from '@/app/img/avatar1.webp'
import Image from 'next/image';
import cookies from 'js-cookie';
import { useRouter } from 'next/navigation';
import Link from 'next/link';

const AccountSection = ({ className, student }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const roouter = useRouter();
    
    const logout = () => {
        cookies.remove('student');
        cookies.remove('aspirant');
        cookies.remove('prof_aspirant');

        //later ----  check for loaction to determine where to redirect to 
        
        // roouter.replace('/');
        window.location.href = '/'
    }

    return (
        <div className={`${className}`}>
            <div className='flex items-center justify-between mr-5'>
                <div className="flex items-center gap-2">
                    {/* <div className="lg:block xs:hidden text-black dark:text-gray-200 cursor-pointer"> */}
                    <div className="text-black cursor-pointer">
                        <p className='hidden md:flex text-md pt-3'>{student?.first_name} {student?.last_name}</p>
                    </div>
                    {/* User dropdown menu */}
                    <div className="ml-4 relative">
                        <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false">
                            <span className="sr-only">Open user menu</span>
                            <Image className="w-10 h-10 rounded-full" src={avatar} alt="student pic" />
                        </button>
                        {isDropdownOpen && (
                            <div className="absolute right-0 w-48 py-1 mt-2 bg-white rounded-md shadow-lg dark:bg-gray-700">
                                <Link href='/student/dashboard' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 cursor-pointer">Dashboard</Link>
                                <div onClick={logout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 cursor-pointer">Log out</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSection