import(/* webpackMode: "eager" */ "/Users/Hudhayfah/Documents/Codes/Gigs/TNM/tnm_portal/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/Hudhayfah/Documents/Codes/Gigs/TNM/tnm_portal/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/Users/Hudhayfah/Documents/Codes/Gigs/TNM/tnm_portal/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/Hudhayfah/Documents/Codes/Gigs/TNM/tnm_portal/src/app/component/header.jsx");
;
import(/* webpackMode: "eager" */ "/Users/Hudhayfah/Documents/Codes/Gigs/TNM/tnm_portal/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/Users/Hudhayfah/Documents/Codes/Gigs/TNM/tnm_portal/node_modules/react-toastify/dist/ReactToastify.css");
